.history__wrapper {
  transition: all 0.3s ease 0s;
  display: flex;
  flex-direction: column;
}

.history__header {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
}

.ui.attached.segment.history__header {
  top: 56px;
  position: sticky;
  z-index: 1000;
}

.ui.grid > .row.history__header-row {
  background-color: #e2e2e2;
  box-shadow: 0 2px 10px -5px #021740;
}

.history__container {
  min-height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #eceff1;
}

.history__container-sidebar {
  position: relative;
  top: 40px;
}

.history__date-divider {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0;
  color: rgb(131, 146, 165);
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.history__date-divider:before {
  content: '';
  display: block;
  flex: 1 1 0;
  height: 1px;
  background-color: rgb(190, 193, 206);
}

.history__date-divider:after {
  content: '';
  display: block;
  flex: 1 1 0;
  height: 1px;
  background-color: rgb(190, 193, 206);
}

.history__date-divider span {
  margin-left: 1rem;
  margin-right: 1em;
}

.ui.floating.label.module-history-badge {
  position: relative;
  left: 3%;
  background: none;
}

.history__badge {
  font-size: 10px;
  font-weight: 500;
  padding: 3px 8px 4px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  color: rgb(255, 255, 255);
  border-radius: 3px;
}

.badge-info {
  background-color: rgb(0, 184, 212);
}

.badge-success {
  background-color: rgb(16, 183, 89);
}

.badge-danger {
  background-color: rgb(220, 53, 69);
}

.badge-primary {
  background-color: rgb(1, 104, 250);
}

.badge-light {
  background-color: rgb(205, 212, 224);
  color: rgb(28, 39, 60);
}

.badge-dark {
  background-color: rgb(59, 72, 99);
}

.badge-warning {
  background-color: rgb(255, 193, 7);
}

.history__user-list-item {
  margin-top: 2px;
  padding: 6px 10px;
  align-items: center;
  color: rgb(27, 46, 75);
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
}

.history__user-list-item:focus,
.history__user-list-item:hover {
  background-color: rgb(245, 246, 250);
}

.history__user {
  position: relative;
  width: 32px;
  height: 32px;
}

.history__user:after {
  width: 7px;
  height: 7px;
  content: '';
  position: absolute;
  right: 2px;
  bottom: 2px;
  border-radius: 100%;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px;
  background-color: rgb(192, 204, 218);
}

.history__user-connected {
  position: relative;
  width: 32px;
  height: 32px;
}

.history__user-connected:after {
  width: 7px;
  height: 7px;
  content: '';
  position: absolute;
  right: 2px;
  bottom: 2px;
  border-radius: 100%;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px;
  background-color: rgb(16, 183, 89);
}

.history__user-avatar {
  position: absolute;
  inset: 0;
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  background-color: $color-brand;
  border-radius: 50%;
}

.history__user-details {
  margin-left: 10px;
  flex: 1 1 0%;
}

.history__item {
  align-items: center;
  color: rgb(27, 46, 75);
  display: flex;
  flex-flow: row wrap;
  padding: 0.2em 0;
}

.history__list-item {
  padding: 0.25em 0.5em !important;
}

.history__item-text {
  margin-left: 0.7rem;
}

.history__comment-form {
  margin-top: 1rem;
}

.ui.button.history__form-button-mobile {
  margin-bottom: 1rem;
}

.history__diffpatch-added {
  background: #bbffbb;
  padding: 0 0.3rem;
  text-decoration: none;
}

.history__diffpatch-deleted {
  background: #ffbbbb;
  text-decoration: line-through;
  padding: 0 0.3rem;
}

.history__diffpatch-path {
  background: #cbcaca;
  padding: 0 0.3rem;
}

.history__item-tree-line {
  padding-left: 2.37rem;
}

.history__search > input {
  @media screen and (max-width: 600px) {
    font-size: 100% !important;
  }
}
