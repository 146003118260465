.contents__card {
  box-shadow: 0 2px 3px rgba(56, 59, 61, 0.05);
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #f0f1f2;
  border-radius: 0.1875rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  margin-bottom: 2rem;
}

.contents__card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #e8eaef;
  border-bottom: 1px solid #f0f1f2;
}

.contents__card-header:first-child {
  border-radius: calc(0.1875rem - 1px) calc(0.1875rem - 1px) 0 0;
}

.contents__card-title {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-weight: 600;
  color: rgba(57, 68, 77, 0.84);
  font-size: 1.25rem;
}

.contents__card-title:last-child {
  margin-bottom: 0;
}

.contents__list-group {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 0;
  border-bottom-width: 0;
  padding-left: 0;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}

.contents__list-group:last-child {
  border-bottom-right-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px);
}

.contents__list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #f0f1f2;
  border-top-color: rgb(240, 241, 242);
  border-right-style: none;
  border-left-style: none;
}

.contents__list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.contents__list-group-item:first-of-type {
  border-top: none;
}

.contents__media {
  display: flex;
  align-items: flex-start;
  margin-top: 0;
}

.contents__media-left {
  padding-right: 0.625rem;
  color: #b8bdc2 !important;
}

.contents__media-right {
  padding-left: 0.625rem;
}

.contents__media-body {
  flex: 1;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #383b3d;
}
