.form-builder__field-container {
  margin-bottom: 1rem;
}

.text-field-input,
.dropdown-picker {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5;
}

.checkbox-option,
.radio-option {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}

.checkbox-option input,
.radio-option input {
  margin-right: 0.5rem;
}

.form-builder__toolbar {
  margin: 2rem 0 2rem 0;
}

.form-builder__vcenter {
  display: flex;
  align-items: center;
}
