@import './variables.scss';

$mobile-breakpoint: 700px;

.learning-item {
  display: flex;
  // @media screen and (max-width: $mobile-breakpoint) {
  //   flex-direction: column;
  // }
}

.learning-item__body {
  flex-grow: 2;
}

.learning-item__header {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
    h5 {
      margin-bottom: 5px;
    }
    .learning-item__title-ctas {
      white-space: nowrap;
      margin-bottom: 5px;
      vertical-align: center;

      a {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    flex-grow: 2;
  }
}

.learning-item__description {
  padding: 20px;
  white-space: pre-line;

  p {
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-line;
  }
}

.learning-item__image {
  align-self: flex-start; // important for avoiding ie11 image distortion
  border: solid 1px $color-border;
}

.learning-item--module {
  margin-bottom: 10px;
  margin-left: -10px;
  padding: 10px;
  border-bottom: 1px solid $color-border;
  box-shadow: 0px 1px 5px rgba(100, 100, 100, 0);
  transition: box-shadow 0.26s ease-in-out;
  &:last-child {
    border: 0;
  }
}

.learning-menu__modules-folder-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: solid 2px;
  margin-right: 5px;

  &.learning-menu__modules-folder-icon--red {
    border-color: red;
  }
  &.learning-menu__modules-folder-icon--aqua {
    border-color: aquamarine;
  }
  &.learning-menu__modules-folder-icon--green {
    border-color: chartreuse;
  }
  &.learning-menu__modules-folder-icon--purple {
    border-color: orchid;
  }
}

.learning-featured-modules__row {
  display: flex;
  align-items: center;
  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
  }
}

.learning-featured-modules__ctas-wrapper {
  margin-left: auto;
  white-space: nowrap;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-left: 0px;
    margin-right: auto;
  }
}

.ui.radio.checkbox {
  margin: 0px 20px 20px 0px;
}

i.large.icon.learning-series-icon,
i.large.icon.disabled {
  margin-right: 1rem !important;
}

.learning-series-icon:hover {
  color: #0d71bb;
}

.ui.floating.label.learning-series-module-badge {
  position: relative;
  left: 20%;
  background: none;
}

.a4-aspect {
  aspect-ratio: 1.414286;
}
