$grey-tone-0: #2c2c2c;
$grey-tone-1: #4f4f4f;
$grey-tone-2: #818181;
$grey-tone-3: #b5b5b5;
$grey-tone-4: #d6d6d6;
$grey-tone-5: #e4e4e4;

$color-brand: #001533;
$color-brand-complement: #2692e7;
$color-brand-complement-lightest: #cce7f8;
$color-border: $grey-tone-5;
