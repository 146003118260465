.ghost-banner {
  height: 60px !important;
  position: sticky;
  top: 56px;
  z-index: 100;
  padding: 11px 0;
  border: none;
  background-color: #a64323;
}

.ghost-banner__inner {
  align-items: center;
  color: white;
  font-size: 2em;
  text-align: center;

  span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  }
}
