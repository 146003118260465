table.inline-editor {
  border: 1px solid rgba(34, 36, 38, 0.1);
  border-radius: 3px;
  max-width: 100%;

  th,
  td {
    text-align: left;
    vertical-align: top;
    background-color: white;
  }

  th {
    font-weight: bold;
    padding: 14px 10px;
    width: 10em;
  }

  td {
    padding: 10px;
  }

  textarea {
    width: 100%;
    display: block;
    font-family: monospace;
    margin: 0;
    line-height: 1.42em;
  }
}

table.inline-editor.fluid {
  width: 100%;
}
