.chat {
  display: flex;
}

.chat__conversations {
  margin-right: 1em;
}

.chat__content {
  flex-grow: 2;
}

.chat__conversation {
  position: relative;

  a,
  .fake-link {
    display: flex;
    align-items: center;
    min-height: 38px;
    padding: 5px 0;
    cursor: pointer;
    color: #4183c4;
  }
}

.chat__conversation--current {
  background-color: #f6f6fe;
}

.chat__conversation .unread-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.chat__conversation--pending-user {
  opacity: 0.5;
}
